<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "FileIcon",
  props: {
    fileExtension: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "40",
    },
    height: {
      type: String,
      default: "40",
    },
  },
  methods: {
    getBadgeColor(): string {
      const extension = this.fileExtension.toLowerCase();
      switch (extension) {
        case "pdf":
          return "#D92D20";
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
        case "mp4":
        case "avi":
        case "mov":
        case "doc":
        case "docx":
          return "#155EEF";
        default:
          return "#7F56D9";
      }
    },

    getBadgeWidth(): string {
      return this.fileExtension.length > 3 ? "31" : "25";
    },

    getBadgeX(): string {
      return this.fileExtension.length > 3 ? "-3" : "-2";
    },

    getTextX(): string {
      return this.fileExtension.length > 3 ? "0" : "1";
    },
  },
});
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="-3 0 36 40" fill="none">
    <path
      d="M28.5 39.25H4.5C2.70507 39.25 1.25 37.7949 1.25 36V4C1.25 2.20508 2.70508 0.75 4.5 0.75H20.5C20.6212 0.75 20.7375 0.798159 20.8232 0.883883L31.6161 1.6768C3.7018 11.7625 31.75 11.8788 31.75 12V36C31.75 37.7949 30.2949 39.25 28.5 39.25Z"
      fill="white"
      stroke="#D0D5DD"
      stroke-width="1.5"
    />
    <path d="M20.5 0.5V8C20.5 10.2091 22.2909 12 24.5 12H32" stroke="hsla(216, 16%, 84%, 1)" stroke-width="1.5" />
    <!-- Badge -->
    <rect :x="getBadgeX()" y="20" :width="getBadgeWidth()" height="15" :fill="getBadgeColor()" />
    <text :x="getTextX()" y="30" font-family="Poppins" font-size="9px" fill="white" font-weight="700">
      {{ fileExtension.toUpperCase() }}
    </text>
  </svg>
</template>
