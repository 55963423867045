// Import only the specific icons we need
import { library } from "@fortawesome/fontawesome-svg-core";

// Import specific icons from free-brands-svg-icons
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons/faTwitterSquare";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons/faWhatsappSquare";
import { faStackExchange } from "@fortawesome/free-brands-svg-icons/faStackExchange";
import { faFileSignature } from "@fortawesome/free-solid-svg-icons/faFileSignature";
import { faChartPie } from "@fortawesome/free-solid-svg-icons/faChartPie";

// Import specific icons from free-solid-svg-icons
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons/faArrowAltCircleRight";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons/faArrowAltCircleUp";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons/faArrowAltCircleDown";
import { faArchive } from "@fortawesome/free-solid-svg-icons/faArchive";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faCopy } from "@fortawesome/free-solid-svg-icons/faCopy";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons/faExpandAlt";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons/faFileCsv";
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { faFolder } from "@fortawesome/free-solid-svg-icons/faFolder";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons/faFolderOpen";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faImage } from "@fortawesome/free-solid-svg-icons/faImage";
import { faInfo } from "@fortawesome/free-solid-svg-icons/faInfo";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPalette } from "@fortawesome/free-solid-svg-icons/faPalette";
import { faPen } from "@fortawesome/free-solid-svg-icons/faPen";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faQuestion } from "@fortawesome/free-solid-svg-icons/faQuestion";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faRandom } from "@fortawesome/free-solid-svg-icons/faRandom";
import { faSave } from "@fortawesome/free-solid-svg-icons/faSave";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faShare } from "@fortawesome/free-solid-svg-icons/faShare";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faTable } from "@fortawesome/free-solid-svg-icons/faTable";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faTrash } from "@fortawesome/free-solid-svg-icons/faTrash";
import { faUpload } from "@fortawesome/free-solid-svg-icons/faUpload";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle";
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { faThList } from "@fortawesome/free-solid-svg-icons/faThList";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons/faUserFriends";
import { faCode } from "@fortawesome/free-solid-svg-icons/faCode";
import { faEarthEurope } from "@fortawesome/free-solid-svg-icons/faEarthEurope";
import { faBook } from "@fortawesome/free-solid-svg-icons/faBook";
import { faLifeRing } from "@fortawesome/free-solid-svg-icons/faLifeRing";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons/faRedoAlt";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons/faRotateRight";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faDroplet } from "@fortawesome/free-solid-svg-icons/faDroplet";
import { faPercent } from "@fortawesome/free-solid-svg-icons/faPercent";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faPlay } from "@fortawesome/free-solid-svg-icons/faPlay";
import { faCrown } from "@fortawesome/free-solid-svg-icons/faCrown";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons/faCircleUser";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faTicketAlt } from "@fortawesome/free-solid-svg-icons/faTicketAlt";
import { faReceipt } from "@fortawesome/free-solid-svg-icons/faReceipt";
import { faPlug } from "@fortawesome/free-solid-svg-icons/faPlug";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faRedo } from "@fortawesome/free-solid-svg-icons/faRedo";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons/faArrowsRotate";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons/faAddressCard";
import { faFingerprint } from "@fortawesome/free-solid-svg-icons/faFingerprint";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons/faCircleExclamation";
import { faClipboard } from "@fortawesome/free-solid-svg-icons/faClipboard";
import { faFileCircleCheck } from "@fortawesome/free-solid-svg-icons/faFileCircleCheck";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons/faCirclePlay";
import { faCirclePause } from "@fortawesome/free-solid-svg-icons/faCirclePause";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { faEnvelopeCircleCheck } from "@fortawesome/free-solid-svg-icons/faEnvelopeCircleCheck";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons/faDoorOpen";
import { faHandshake } from "@fortawesome/free-solid-svg-icons/faHandshake";
import { faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons/faHandHoldingDollar";
import { faSmog } from "@fortawesome/free-solid-svg-icons/faSmog";
import { faCarSide } from "@fortawesome/free-solid-svg-icons/faCarSide";

// Import specific icons from pro-regular-svg-icons
import { faSailboat } from "@fortawesome/pro-regular-svg-icons/faSailboat";
import { faPlane } from "@fortawesome/pro-regular-svg-icons/faPlane";
import { faCaravanSimple } from "@fortawesome/pro-regular-svg-icons/faCaravanSimple";
import { faMotorcycle } from "@fortawesome/pro-regular-svg-icons/faMotorcycle";
import { faCaravan } from "@fortawesome/pro-regular-svg-icons/faCaravan";
import { faVanShuttle } from "@fortawesome/pro-regular-svg-icons/faVanShuttle";
import { faTrailer } from "@fortawesome/pro-regular-svg-icons/faTrailer";
import { faTruckField } from "@fortawesome/pro-regular-svg-icons/faTruckField";
import { faBicycle } from "@fortawesome/pro-regular-svg-icons/faBicycle";
import { faMoped } from "@fortawesome/pro-regular-svg-icons/faMoped";
import { faShieldCheck } from "@fortawesome/pro-regular-svg-icons/faShieldCheck";
import { faFileMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons/faFileMagnifyingGlass";
import { faCheckSquare } from "@fortawesome/pro-regular-svg-icons/faCheckSquare";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import { faChartMixed } from "@fortawesome/pro-regular-svg-icons/faChartMixed";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons/faUserPlus";
import { faBullseyePointer } from "@fortawesome/pro-regular-svg-icons/faBullseyePointer";
import { faFilterList } from "@fortawesome/pro-regular-svg-icons/faFilterList";
import { faFloppyDisk } from "@fortawesome/pro-regular-svg-icons/faFloppyDisk";
import { faSparkles } from "@fortawesome/pro-regular-svg-icons/faSparkles";
import { faTableColumns } from "@fortawesome/pro-regular-svg-icons/faTableColumns";
import { faRotateLeft } from "@fortawesome/pro-regular-svg-icons/faRotateLeft";
import { faBadgeCheck } from "@fortawesome/pro-regular-svg-icons/faBadgeCheck";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons/faPenToSquare";
import { faUsers } from "@fortawesome/pro-regular-svg-icons/faUsers";
import { faX } from "@fortawesome/pro-regular-svg-icons/faX";
import { faRectangleVerticalHistory } from "@fortawesome/pro-regular-svg-icons/faRectangleVerticalHistory";
import { faFileImport } from "@fortawesome/pro-regular-svg-icons/faFileImport";
import { faNoteSticky } from "@fortawesome/pro-regular-svg-icons/faNoteSticky";
import { faPenField } from "@fortawesome/pro-regular-svg-icons/faPenField";
import { faFilters } from "@fortawesome/pro-regular-svg-icons/faFilters";
import { faFileArrowDown } from "@fortawesome/pro-regular-svg-icons/faFileArrowDown";
import { faIdCard } from "@fortawesome/pro-regular-svg-icons/faIdCard";
import { faSquare } from "@fortawesome/pro-regular-svg-icons/faSquare";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { faRoute } from "@fortawesome/pro-regular-svg-icons/faRoute";
import { faThumbsUp } from "@fortawesome/pro-regular-svg-icons/faThumbsUp";
import { faClipboardCheck } from "@fortawesome/pro-regular-svg-icons/faClipboardCheck";
import { faGrid2Plus } from "@fortawesome/pro-regular-svg-icons/faGrid2Plus";

// Add all icons to the library
export const icons = {
  // Brands
  faFacebook,
  faFacebookSquare,
  faTwitter,
  faTwitterSquare,
  faWhatsapp,
  faWhatsappSquare,
  faStackExchange,
  faFileSignature,
  faHandshake,
  faHandHoldingDollar,
  faSmog,
  faCarSide,
  faChartPie,

  // Solid
  faAngleDoubleRight,
  faAngleDown,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faArrowAltCircleUp,
  faArrowAltCircleDown,
  faArchive,
  faBars,
  faBell,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCog,
  faCopy,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExpandAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFilePdf,
  faFileCsv,
  faFilter,
  faFolder,
  faFolderOpen,
  faHome,
  faImage,
  faInfo,
  faInfoCircle,
  faLink,
  faList,
  faPalette,
  faPen,
  faPlus,
  faQuestion,
  faQuestionCircle,
  faRandom,
  faSave,
  faSearch,
  faShare,
  faSignOutAlt,
  faSpinner,
  faTable,
  faTimes,
  faTrash,
  faUpload,
  faUser,
  faUserCircle,
  faXmark,
  faThList,
  faUserFriends,
  faCode,
  faEarthEurope,
  faBook,
  faLifeRing,
  faRedoAlt,
  faRotateRight,
  faExclamation,
  faDroplet,
  faPercent,
  faLock,
  faPlay,
  faCrown,
  faCircleUser,
  faArrowDown,
  faClipboardList,
  faClock,
  faExclamationTriangle,
  faTicketAlt,
  faReceipt,
  faPlug,
  faExternalLinkAlt,
  faRedo,
  faArrowsRotate,
  faAddressCard,
  faFingerprint,
  faCircleExclamation,
  faClipboard,
  faFileCircleCheck,
  faCirclePlay,
  faCirclePause,
  faCirclePlus,
  faPaperPlane,
  faEnvelopeCircleCheck,
  faDoorOpen,

  // Pro Regular
  faSailboat,
  faPlane,
  faCaravanSimple,
  faMotorcycle,
  faCaravan,
  faVanShuttle,
  faTrailer,
  faTruckField,
  faBicycle,
  faMoped,
  faShieldCheck,
  faFileMagnifyingGlass,
  faCheckSquare,
  faCircleInfo,
  faChartMixed,
  faUserPlus,
  faBullseyePointer,
  faFilterList,
  faFloppyDisk,
  faSparkles,
  faTableColumns,
  faRotateLeft,
  faBadgeCheck,
  faPenToSquare,
  faUsers,
  faX,
  faRectangleVerticalHistory,
  faFileImport,
  faNoteSticky,
  faPenField,
  faFilters,
  faFileArrowDown,
  faIdCard,
  faSquare,
  faGear,
  faRoute,
  faThumbsUp,
  faClipboardCheck,
  faGrid2Plus,
};

// Add icons to library
Object.values(icons).forEach((icon) => {
  if (icon) {
    library.add(icon);
  }
});

export default icons;
